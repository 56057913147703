import * as React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { navigate } from "gatsby"

import Button from "../../components/ui/buttons"
import ContainerLayout from "../../components/ui/containerLayout"

import { useI18n } from "../../../locale.js"

import greenBrackets from "../../assets/images/elements/greenSmallBrackets.svg"
import fallingStar from "../../assets/images/elements/fallingStar.svg"
import helmet from '../../assets/images/elements/helmetWhite.png'
import greenCodeStrings from "../../assets/images/elements/greenCodeStrings.png"
import greyCodeStrings from "../../assets/images/elements/greyCodeStrings.png"
import codeStringAlternative from "../../assets/images/elements/codeStringAlternative.png"

const Main = ({ city }) => {
  const breakpoints = useBreakpoint()

  return (
    <ContainerLayouStyled>
      {breakpoints.medium ? null : (
        <StarStyled src={fallingStar} alt="Falling star" />
      )}

      <TitleStyled>
        <b>{useI18n('thankYou.title')}</b>
      </TitleStyled>

      <SubtitleStyled>
        {useI18n('thankYou.order1')}
        <br /> {useI18n('thankYou.order2')}
        <br /> {useI18n('thankYou.order3')}
      </SubtitleStyled>

      <CodeStringLeftStyled src={greenCodeStrings} alt="Matrix code" />
      <CodeStringGreenLeftStyled
        src={codeStringAlternative}
        alt="Matrix code"
      />

      <StarLeftStyled src={fallingStar} alt="Falling star" />
      <StarStyled src={fallingStar} alt="Falling star" />

      <HemletImageStyled src={helmet} alt="VR helmet" />

      <CodeStringBottomStyled src={greenCodeStrings} alt="Matrix code" />
      <CodeStringRightStyled src={greyCodeStrings} alt="Matrix code" />

      <ButtonStyled
        variant="solid"
        title={useI18n('thankYou.backToMain')}
        onClick={() => navigate(`/${city.slug}`)}
        defaultSize
      />

      <HintTextStyled>
        {useI18n('thankYou.hint1')}
        <br /> {useI18n('thankYou.hint2')} <br />
        <b>{useI18n('thankYou.hint3')}</b>
      </HintTextStyled>
    </ContainerLayouStyled>
  )
}
export default Main

const ContainerLayouStyled = styled(ContainerLayout)`
  flex-direction: column;

  margin-top: 2rem;
  padding-bottom: 0;
`

const TitleStyled = styled.p`
  margin-bottom: 2rem;

  font-size: 2.6rem;
  line-height: 1.28;
  text-align: center;
  color: ${props => props.theme.colors.green};

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }
`

const SubtitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px 40px;

  position: relative;

  font-size: 1rem;
  text-align: center;
  line-height: 1.1;

  z-index: 1;

  &:before {
    content: "";
    width: 24px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background-image: url(${greenBrackets});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
  }

  &:after {
    content: "";
    height: 100%;
    width: 24px;
    position: absolute;
    top: 0;
    right: 0;

    background-image: url(${greenBrackets});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 15px 30px;
  }
  @media ${props => props.theme.breakpoints.small} {
    width: 100%;
    br {
      display: none;
    }
  }
`

const HemletImageStyled = styled.img`
  margin-top: 50px;
  width: 310px;
  height: 230px;

  object-fit: contain;
  z-index: 1;

  @media ${props => props.theme.breakpoints.small} {
    margin-top: 100px;
    margin-bottom: 70px;
    width: 190px;
    height: 140px;
  }
`

const StarLeftStyled = styled.img`
  width: 110px;
  height: 65px;

  position: absolute;
  top: 65%;
  left: 5%;
  z-index: 1;

  @media ${props => props.theme.breakpoints.small} {
    left: -10%;
    top: 70%;
  }
`
const StarStyled = styled.img`
  width: 140px;
  height: 80px;

  position: absolute;
  top: 35%;
  right: -5%;
  z-index: 1;
`

const ButtonStyled = styled(Button)`
  margin-top: 50px;

  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`
const HintTextStyled = styled.p`
  margin-top: 50px;

  font-size: 1rem;
  line-height: 1.6;
  text-align: center;

  @media ${props => props.theme.breakpoints.small} {
    br {
      display: none;
    }
  }
`

const CodeStringLeftStyled = styled.img`
  width: 160px;
  height: 410px;

  position: absolute;
  top: 20%;
  left: 0%;

  @media ${props => props.theme.breakpoints.small} {
    width: 120px;
    height: 360px;

    left: -10%;
    top: 30%;
  }
`

const CodeStringGreenLeftStyled = styled.img`
  width: 160px;
  height: 410px;

  position: absolute;
  top: 40%;
  left: 15%;

  @media ${props => props.theme.breakpoints.small} {
    width: 120px;
    height: 360px;
  }
`
const CodeStringBottomStyled = styled.img`
  width: 160px;
  height: 430px;

  position: absolute;
  top: 30%;
  right: 10%;

  @media ${props => props.theme.breakpoints.small} {
    width: 120px;
    height: 360px;
    top: 35%;
    right: 20%;
  }
`
const CodeStringRightStyled = styled.img`
  width: 160px;
  height: 410px;

  position: absolute;
  top: 20%;
  right: 0%;
  @media ${props => props.theme.breakpoints.small} {
    width: 120px;
    height: 360px;
    right: -5%;
  }
`
