import * as React from 'react'
import styled from 'styled-components'

import ContainerLayout from '../../components/ui/containerLayout'

import { useI18n } from '../../../locale.js'

import greenBrackets from '../../assets/images/elements/greenSmallBrackets.svg'

const Rules = () => {
  return (
    <ContainerLayout>
      <TitleStyled>
        <b>{useI18n('thankYou.rules')}</b>
      </TitleStyled>

      <WrapperStyled>
        {/* <ItemStyled>
          <ItemTitleStyled>{useI18n("main.rules.password")}</ItemTitleStyled>
          <ItemDescriptionStyled>
            {useI18n("main.rules.passwordDetails")}
          </ItemDescriptionStyled>
        </ItemStyled> */}

        <ItemStyled>
          <ItemTitleStyled>{useI18n('main.rules.alcohol')}</ItemTitleStyled>
          <ItemDescriptionStyled>
            {useI18n('main.rules.warning')}
          </ItemDescriptionStyled>
        </ItemStyled>

        <ItemStyled>
          <ItemTitleStyled>
            {useI18n('main.rules.age')}
            <br /> {useI18n('main.rules.restriction')}
          </ItemTitleStyled>
          <ItemDescriptionStyled>
            {useI18n('main.rules.restrictionDetails')}
          </ItemDescriptionStyled>
        </ItemStyled>

        <ItemStyled>
          <ItemTitleStyled>{useI18n('main.rules.pregnant')}</ItemTitleStyled>
          <ItemDescriptionStyled>
            {useI18n('main.rules.pregnantDetails')}
          </ItemDescriptionStyled>
        </ItemStyled>

        <ItemStyled>
          <ItemTitleStyled>{useI18n('main.rules.visit')}</ItemTitleStyled>
          <ItemDescriptionStyled>
            {useI18n('main.rules.visitDetails')}
          </ItemDescriptionStyled>
        </ItemStyled>

        <ItemStyled>
          <ItemTitleStyled>
            {useI18n('main.rules.required')}
            <br /> {useI18n('main.rules.childAccompany')}
          </ItemTitleStyled>
          <ItemDescriptionStyled>
            {useI18n('main.rules.accompanyDetails')}
          </ItemDescriptionStyled>
        </ItemStyled>
      </WrapperStyled>
    </ContainerLayout>
  )
}
export default Rules

const TitleStyled = styled.p`
  margin-bottom: 5rem;

  font-size: 2.2rem;
  line-height: 1.28;
  text-align: center;
  @media ${(props) => props.theme.breakpoints.small} {
    margin-bottom: 2rem;

    font-size: 1.7rem;
    line-height: 1.1;
  }
`

const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 60px;

  @media ${(props) => props.theme.breakpoints.medium} {
    grid-column-gap: 25px;
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`
const ItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 20px 0;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 20px 0px;
  }
  @media ${(props) => props.theme.breakpoints.small} {
    margin: 0 0 3rem;
  }
`

const ItemTitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 1rem;
  padding: 10px 30px;

  position: relative;

  font-size: 1.2rem;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.futuraDemi};

  z-index: 1;
  height: 50px;

  br {
    display: none;
  }

  &:before {
    content: '';
    width: 15px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background-image: url(${greenBrackets});
    background-size: 15px 50px;
    background-repeat: no-repeat;
    background-position: bottom;
    transform: rotate(180deg);
  }

  &:after {
    content: '';
    height: 100%;
    width: 15px;
    position: absolute;
    top: 0;
    right: 0;

    background-image: url(${greenBrackets});
    background-size: 15px 50px;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    width: unset;
    br {
      display: block;
    }
  }
`
const ItemDescriptionStyled = styled.p`
  font-size: 1rem;
  line-height: 1.41;
  text-align: center;
`
