import { graphql } from "gatsby"
import * as React from "react"

import styled from "styled-components"

import NavBar from "../../components/navbar"
import Seo from "../../components/seo"
import Main from "./Main"
import Rules from "./Rules"

const ThankYouPage = ({ pageContext }) => {
  return (
    <ContainerStyled id="thank-you-page">
      <NavBar city={pageContext} />
      <Main city={pageContext} />
      <Rules />
    </ContainerStyled>
  )
}

export const Head = ({ pageContext }) => (
  <>
    <SeoData context={pageContext} />
    <script
      dangerouslySetInnerHTML={{
        __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '1258133411516313'); 
fbq('track', 'PageView');
            `
      }}
    />
    <noscript>
      <img height="1" width="1"
        src="https://www.facebook.com/tr?id=1258133411516313&ev=PageView
&noscript=1"/>
    </noscript>
    {!!pageContext.fbPersonalId &&
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '${pageContext.fbPersonalId}'); 
fbq('track', 'PageView');
            `
          }}
        />
        <noscript>
          <img height="1" width="1"
            src={`https://www.facebook.com/tr?id=${pageContext.fbPersonalId}&ev=PageView
&noscript=1`} />
        </noscript>
      </>
    }
  </>
)
const SeoData = ({ context }) => {
  const [lang, setLang] = React.useState('ua')
  return (
          <Seo
          context={context}
          title={`Дякуємо за ваше замовлення`}
          description={`Клуб MATRIX у ${context.declension[lang]} пропонує своїм клієнтам тільки ті ігри, що відрізняються чудовою графікою та чітким зображенням. Це є запорукою максимального занурення та ваших позитивних емоцій.`}
         />
  )
}

export default ThankYouPage

const ContainerStyled = styled.div`
  min-height: 100vh;
  background: #030303;
  overflow: hidden;
`
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;